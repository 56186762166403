<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-24 14:50:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:24:43
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/LeftMain/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="left-main">
    <div class="left-head">
      <a-input @change="handleChangeName" v-model.trim="query.name" placeholder="请输入广告位名称/ID" class="search-item"></a-input>
    </div>
    <a-button @click="handleAddPlace" :disabled="disabled" type="primary" class="left-btn">添加到配置列表</a-button>
    <div class="publisher-list">
      <PublisherCheckBox
        @checkedPlaceForPublisher="checkedPlaceForPublisher"
        :currentCheckedIdList="currentCheckedIdList"
        :checkedIdList="checkedIdList"
        v-for="publisher in treeList"
        :key="publisher.publisherId"
        :publisherInfo="publisher"/>
    </div>
  </div>
</template>

<script>
import { getPnblisherAndPlaceTreeList } from '@/apiForManage/budget/config'
import PublisherCheckBox from './PublisherCheckBox'
export default {
  name: 'PlaceLeftMain',
  components: { PublisherCheckBox },
  data () {
    return {
      query: {
        name: undefined,
        dspId: undefined
      },
      timer: null,
      treeList: [],
      currentCheckedIdList: []
    }
  },
  props: {
    checkedIdList: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    checkedIdList: {
      handler (newVal, oldVal) {
        if (oldVal && oldVal.every((item) => newVal.includes(item)) && newVal.length === oldVal.length) {
          return
        }
        this.currentCheckedIdList = [...newVal]
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.query.dspId = this.$route.query.id
    this.getPlaceTeeList()
  },
  computed: {
    disabled () {
      return this.currentCheckedIdList.length === this.checkedIdList.length
    }
  },
  methods: {
    handleChangeName () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getPlaceTeeList()
        this.timer = null
      }, 500)
    },
    handleAddPlace () {
      // 将后续勾选的id列表抛出
      this.$emit('addSource', this.currentCheckedIdList.filter((id) => !this.checkedIdList.includes(id)))
    },
    // 传入所有选中id
    checkedPlaceForPublisher (ids) {
      this.currentCheckedIdList = ids
    },
    async getPlaceTeeList () {
      const { data = {} } = await getPnblisherAndPlaceTreeList({ type: '2', ...this.query })
      const { items = [] } = data
      this.treeList = items
    }
  }
}
</script>

<style lang="less" scoped>
.left-main {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .left-head{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid @compBorderColor;
    padding-bottom: 11px;
    .search-item{
      width: 100%;
    }
  }
  .left-btn{
    margin: 20px 0 14px 0;
  }
  .publisher-list{
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
